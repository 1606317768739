import React, { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import Image from 'gatsby-image'
import styled from 'styled-components'

import useWindowWidth from '../hooks/useWindowWidth'

// Note: This probably seems convoluted at first, but due to the way React
// hydrates server-rendered content I needed a more sophisticated method of
// controlling the presence of a video. It was important to avoid loading these
// big, autoplaying videos on mobile devices and this decision was a large
// contributor to a good Lighthouse score :)
//
// It wasn't enough to simply render a different video element with a different
// source—even across re-renders, I saw a stale src attribute. So I turned to
// a more imperative approach that modifies the DOM node directly.

const FullScreenVideo = ({
  src,
  firstFrame,
  placeholder,
  height,
  ...props
}) => {
  // listen for window resize events to get window width
  const width = useWindowWidth()
  const [ref, inView] = useInView({
    threshold: 0.7,
    triggerOnce: true
  })
  // determine if we're in the browser (vs. SSR)
  // const [isInWindow, setIsInWindow] = useState(typeof window !== 'undefined')
  // the "switch" that controls whether or not to show the video based on screen size
  const [shouldShowVideo, setShouldShowVideo] = useState(width > 768)
  // a reference to the video node to add or remove the src
  const videoElement = useRef(null)
  // this effect watches the window width to flip the "switch"
  useEffect(() => {
    if (width > 768) {
      setShouldShowVideo(true)
    } else {
      setShouldShowVideo(false)
    }
  }, [width])
  // this effect watches the "switch" and updates the video src
  useEffect(() => {
    // setIsInWindow(typeof window !== 'undefined')
    if (shouldShowVideo) {
      videoElement.current.src = src
      videoElement.current.style = ''
    } else {
      videoElement.current.src = ''
      videoElement.current.style = 'display: none;'
    }
  }, [shouldShowVideo, src])
  // TODO: figure out what this logic should really be
  // const image = isInWindow ? firstFrame : placeholder
  return (
    <Wrapper ref={ref}>
      <Image
        fluid={placeholder}
        css={`
          height: 100%;
        `}
        alt=""
      />
      <StyledVideo
        ref={videoElement}
        height={height}
        autoPlay
        muted
        loop
        {...props}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  height: 100%;
`

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // height: ${props => props.height || '100vh'};
  object-fit: cover;
  object-position: ${props => props.position || 'initial'};
`

export default FullScreenVideo
