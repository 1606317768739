import { useState, useEffect } from 'react'

export default function useWindowWidth() {
  var initialState = 0
  if (typeof window !== 'undefined') {
    initialState = window.innerWidth
  }
  const [width, setWidth] = useState(initialState)
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
  return width
}
