import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from '../components/SEO'
import FullScreenVideo from '../components/FullScreenVideo'
import AngledDivider from '../components/AngledDivider'
import Overlay from '../components/Overlay'
import FlexBox from '../components/FlexBox'
import ActionLink from '../components/ActionLink'
import ForegroundLayout from '../components/ForegroundLayout'
import FullWidthWrapper from '../components/FullWidthWrapper'
import BigHeading from '../components/BigHeading'
import Paragraph from '../components/Paragraph'
import ContactSection from '../components/ContactSection'
import ParallaxWrapper from '../components/ParallaxWrapper'
import WordArt from '../components/WordArt'
import ContentBlock from '../components/ContentBlock'

import oaReel from '../videos/reel2.mp4'
import anglrReel from '../videos/anglr.mp4'
import svlReel from '../videos/svl.mp4'
import ArrowWhite from '../graphics/ne-arrow-white.svg'
import ArrowOrange from '../graphics/ne-arrow.svg'
import BookIcon from '../graphics/book.svg'
import DigitalIcon from '../graphics/digital.svg'
import VideoIcon from '../graphics/video.svg'
import AppIcon from '../graphics/app.svg'
import WebIcon from '../graphics/web.svg'
import VisualIcon from '../graphics/visual.svg'
import BeaconIcon from '../graphics/beacon.svg'

const Home = ({ data }) => (
  <React.Fragment>
    <SEO title="Home" />
    <LetsLaunch placeholder={data.heroPlaceholder.childImageSharp.fluid} />
    <BuildYourBrand1 />
    <CaseStudy1
      url={data.featuredWork.edges[0].node.frontmatter.path}
      firstFrame={data.caseStudy1FirstFrame.childImageSharp.fluid}
      placeholder={data.caseStudy1FirstFrame.childImageSharp.fluid}
    />
    <Services />
    <CaseStudy2
      url={data.featuredWork.edges[1].node.frontmatter.path}
      firstFrame={data.caseStudy2FirstFrame.childImageSharp.fluid}
      placeholder={data.caseStudy2FirstFrame.childImageSharp.fluid}
    />
    <BuildYourBrand2 />
    <ContactSection />
  </React.Fragment>
)

const VideoCTA = styled(ActionLink)`
  background-color: #f93616;
  color: #fff;
  padding: 1rem 1.25rem;
  position: absolute;
  bottom: 15%;
  left: 0;
  z-index: 2;
  @media (max-width: 768px) {
    display: none;
  }
`

const LetsLaunch = ({ placeholder }) => (
  <React.Fragment>
    <FullWidthWrapper height="110vh">
      <FullScreenVideo src={oaReel} placeholder={placeholder} height="110vh" />
      <Overlay alpha={0.4} />
      <ParallaxWrapper
        css={`
          top: 0;
        `}
      >
        <HeroHeading />
      </ParallaxWrapper>
      <VideoCTA to="/services/video/library">
        <VideoIcon
          css={`
            width: 25px;
            height: 25px;
            margin-right: 1rem;
          `}
        />
        Full Video Library
      </VideoCTA>
    </FullWidthWrapper>
  </React.Fragment>
)

const BuildYourBrandLink = () => (
  <ActionLink
    to="/branding"
    css={`
      color: #fff;
    `}
  >
    Build Your Brand
    <ArrowWhite
      css={`
        width: 25px;
        height: 25px;
        margin-left: 1rem;
      `}
    />
  </ActionLink>
)

const BuildYourBrand1 = () => (
  <React.Fragment>
    <AngledDivider color="#f93616" position="top" />
    <ContentBlock backgroundColor="#f93616">
      <FlexBox flexDirection="row" itemSpacing="4rem" isResponsive>
        <BigHeading
          css={`
            max-width: 40rem;
          `}
        >
          Brands need stories, data, strategy & design
        </BigHeading>
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          itemSpacing="4rem"
        >
          <Paragraph>
            Orange Astronaut is a full-service creative agency that specializes
            in advertisement, marketing and digital intelligence services. We’re
            data driven creators, developers and marketers who understand the
            science behind brand growth. We combine strategic marketing with
            remarkable cinematography and new technologies to grow your brand.
            Welcome to our world.
          </Paragraph>
          <BuildYourBrandLink />
        </FlexBox>
      </FlexBox>
    </ContentBlock>
    <AngledDivider color="#f93616" position="bottom" />
  </React.Fragment>
)

const CaseStudy1 = ({ url, firstFrame, placeholder }) => (
  <React.Fragment>
    <FullWidthWrapper height="90vh">
      <FullScreenVideo
        src={anglrReel}
        firstFrame={firstFrame}
        placeholder={placeholder}
        height="90vh"
        position="top"
      />
      <Overlay alpha={0.4} />
      <ForegroundLayout
        css={`
          top: -100%;
        `}
      >
        <FlexBox
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          itemSpacing="5vh"
        >
          <BigHeading
            css={`
              text-align: center;
            `}
          >
            The Culture of Fishing
          </BigHeading>
          <ActionLink to={url}>
            See Case Study
            <ArrowOrange
              css={`
                margin-left: 1rem;
              `}
            />
          </ActionLink>
        </FlexBox>
      </ForegroundLayout>
    </FullWidthWrapper>
  </React.Fragment>
)

const services = [
  {
    name: 'Video Production',
    icon: VideoIcon,
    path: '/services/#video-production'
  },
  {
    name: 'Visual Marketing',
    icon: VisualIcon,
    path: '/services/#visual-marketing'
  },
  {
    name: 'Website Design & Development',
    icon: WebIcon,
    path: '/services/#website-design-development'
  },
  {
    name: 'Brand Book Publishing',
    icon: BookIcon,
    path: '/services/#brand-book-publishing'
  },
  {
    name: 'Digital Marketing',
    icon: DigitalIcon,
    path: '/services/#digital-marketing'
  },

  {
    name: 'App Design & Development',
    icon: AppIcon,
    path: '/services/#app-design-development'
  },

  {
    name: 'Beacon Advertising',
    icon: BeaconIcon,
    path: '/services/#beacon-advertising'
  }
]

const Service = ({ name, icon: IconComponent, path }) => {
  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      itemSpacing="1rem"
      useFlexWidth
      css={`
        width: 300px;
        height: 150px;
      `}
    >
      <IconComponent style={{ width: '40px', height: '40px' }} />
      <ActionLink
        to={path}
        css={`
          color: #fff;
          text-align: center;
        `}
      >
        {name}
      </ActionLink>
    </FlexBox>
  )
}

const Services = () => (
  <React.Fragment>
    <AngledDivider color="#1b1b1b" position="top" />
    <ContentBlock backgroundColor="#1b1b1b">
      <BigHeading
        css={`
          text-align: center;
          margin-bottom: 10vh;
        `}
      >
        Services
      </BigHeading>
      <FlexBox
        flexDirection="row"
        justifyContent="space-evenly"
        css={`
          flex-wrap: wrap;
        `}
      >
        {services.map((service, index) => (
          <Service
            key={index}
            name={service.name}
            icon={service.icon}
            path={service.path}
          />
        ))}
      </FlexBox>
    </ContentBlock>
    <AngledDivider color="#1b1b1b" position="bottom" />
  </React.Fragment>
)

const CaseStudy2 = ({ url, firstFrame, placeholder }) => (
  <FullWidthWrapper height="90vh">
    <FullScreenVideo
      src={svlReel}
      firstFrame={firstFrame}
      placeholder={placeholder}
      height="90vh"
      position="center"
    />
    <Overlay alpha={0.4} />
    <ForegroundLayout
      css={`
        top: -100%;
      `}
    >
      <FlexBox
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        itemSpacing="5vh"
      >
        <BigHeading
          css={`
            text-align: center;
          `}
        >
          Rebranding the Four-Post Lift
        </BigHeading>
        <ActionLink to={url}>
          See Case Study
          <ArrowOrange
            css={`
              margin-left: 1rem;
            `}
          />
        </ActionLink>
      </FlexBox>
    </ForegroundLayout>
  </FullWidthWrapper>
)

const BuildYourBrand2 = () => (
  <React.Fragment>
    <AngledDivider color="#f93616" position="top" />
    <ContentBlock backgroundColor="#f93616">
      <FlexBox flexDirection="row" itemSpacing="4rem" isResponsive>
        <BigHeading
          css={`
            max-width: 40rem;
          `}
        >
          Suit Up, Let's Launch
        </BigHeading>
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          itemSpacing="4rem"
        >
          <Paragraph>
            Helping brands communicate what they do, how they do it, and why
            they do it is our forte. Strategic communication has been the
            foundation of our growth. We offer digital marketing, custom web/app
            design and development, video production, content creation and
            digital intelligence services. Using strategic content marketing,
            Orange Astronaut develops multimedia campaigns and advertisements
            for clients across multiple industries.
          </Paragraph>
          <BuildYourBrandLink />
        </FlexBox>
      </FlexBox>
    </ContentBlock>
    <AngledDivider color="#f93616" position="bottom" />
  </React.Fragment>
)

const HeroHeading = () => (
  <WordArt.Wrapper>
    <WordArt.Line color="#f93616" fontSize="0.9em">
      You have ideas
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="0.75em">
      We have creators
    </WordArt.Line>
    <WordArt.Line color="#f93616" fontSize="1.95em">
      Suit up
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="1.1em">
      Let's Launch
    </WordArt.Line>
  </WordArt.Wrapper>
)

export const query = graphql`
  query {
    featuredWork: allMdx(
      limit: 2
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            path
            order
            title
          }
        }
      }
    }
    heroPlaceholder: file(relativePath: { eq: "hero-placeholder.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    caseStudy1FirstFrame: file(relativePath: { eq: "anglr-firstframe.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    caseStudy2FirstFrame: file(relativePath: { eq: "svl-firstframe.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Home
