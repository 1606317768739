import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const ActionLink = ({ to, children, ...props }) => (
  <StyledLink to={to} {...props}>
    {children}
  </StyledLink>
)

const StyledLink = styled(Link)`
  padding: 0.3rem 0.4rem 0.2rem;
  position: relative;
  z-index: 1;
  font-size: 24px;
  font-family: 'Prompt', sans-serif;
  font-weight: 800;
  color: ${props => props.color || '#f93616'};
  text-transform: uppercase;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  &::after {
    content: '';
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    transition: width 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover {
    color: #0a0a0a;
  }
  & svg path {
    transition: fill 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover svg path {
    fill: #0a0a0a;
  }
  &:hover ::after {
    width: 100%;
  }
`

export default ActionLink
